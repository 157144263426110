import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/scss/style.scss';
import Header from './view/common/Header';
import Footer from './view/common/Footer';
import { Route, Routes } from 'react-router-dom';
import Home from './view/Home/Home';
import Customer from './view/customer/Customer';
import Research from './view/research/Research';
import Blog from './view/blog/Blog';
import News from './view/news/News';
import About from './view/about/About';
import Common from './view/Common';
import ContactUs from './view/contactUs/ContactUs';
import TermsAndConditions from './view/terms-conditions/TermsAndConditions';
import PrivacyPolicy from './view/privacy-policy/privacyPolicy';
import ScrollToTop from './ScrollToTop'; 

function App() {
  return (
    <div className="App">
      <ScrollToTop /> 
      <Common />
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/customer' element={<Customer />} />
        <Route path='/research' element={<Research />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/news' element={<News />} />
        <Route path='/about' element={<About />} />
        <Route path='/terms-conditions' element={<TermsAndConditions />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
