import React, { useState } from "react";
import newsData from "../../assets/json/news.json";

function News() {
  const [expandedArticle, setExpandedArticle] = useState(null);

  const toggleArticle = (index) => {
    setExpandedArticle(expandedArticle === index ? null : index);
  };

  return (
    <div className="news-page">
      <div className="container">
        <div className="blog-slider-section">
          <div className="container">
            <div className="theme-title text-center">
              <h2>
                <span>Press Releases</span>
              </h2>
            </div>
          </div>
      </div>
        <div className="row">
          {newsData.map((news, index) => {
            return (
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3"
                key={index}
                style={{ width: "100%", marginTop: "40px" }}
              >
                <div className="news-card">
                  <h3>{news.title}</h3>
                  <p>By: {news.author}</p>
                  <p>
                    {news.content}
                  </p>
                  <button onClick={() => window.location.href = news.link}>
                    Read More
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default News;
